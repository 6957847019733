import { CivicAuth, type AuthConfig } from "@civic/auth/server";
import type {
  CivicWeb3ClientConfig,
  SparseWallet,
  UserDetailsWithAuth,
} from "../types.js";
import type { AuthStorage, User } from "@civic/auth";
import { MetakeepServerWeb3Client } from "../lib/metakeep/MetakeepServerWeb3Client.js";

const addressToWallet = (
  address: string,
  type: SparseWallet["type"],
): SparseWallet => ({
  walletAddress: address,
  type,
});

// Cache the wallets for the user, so that several server components calling getUser()
// do not make several calls to the wallet API.
export const getWallets = async <T extends UserDetailsWithAuth>(
  user: User<T>,
  config?: CivicWeb3ClientConfig,
): Promise<SparseWallet[]> => {
  // Use the server-side Web3Client implementation instead of the browser-based one
  const web3Client = await MetakeepServerWeb3Client.build(config || {}, user);

  return [
    !!web3Client.ethereum?.address &&
      addressToWallet(web3Client.ethereum.address, "ethereum"),
    !!web3Client.solana?.address &&
      addressToWallet(web3Client.solana.address, "solana"),
  ].filter((wallet) => !!wallet) as SparseWallet[];
};

export const getUserWithAuthDetails = async (
  authStorage: AuthStorage,
  authConfig: AuthConfig,
): Promise<User<UserDetailsWithAuth> | null> => {
  const civicAuth = new CivicAuth(authStorage, authConfig);
  const user = await civicAuth.getUser();
  const tokens = await civicAuth.getTokens();
  if (!user?.email || !tokens?.idToken) return null;
  return {
    ...user,
    email: user.email, // this is implicit in the above line but amounts to a type assertion
    idToken: tokens.idToken,
  };
};

export const getUser = async <T extends UserDetailsWithAuth>(
  config: CivicWeb3ClientConfig,
  authStorage: AuthStorage,
  authConfig: AuthConfig,
): Promise<User<T> | null> => {
  const user = (await getUserWithAuthDetails(
    authStorage,
    authConfig,
  )) as User<T>;
  if (!user) return null;

  const wallets = await getWallets(user, config);

  if (wallets.length === 0 || !wallets[0]) {
    return {
      ...user,
      walletAddress: null,
    };
  } else {
    return {
      ...user,
      walletAddress: wallets[0].walletAddress,
    };
  }
};

export const createWallets = async (
  config: CivicWeb3ClientConfig,
  authStorage: AuthStorage,
  authConfig: AuthConfig,
): Promise<SparseWallet[]> => {
  const user = await getUserWithAuthDetails(authStorage, authConfig);
  if (!user) {
    throw new Error("create wallet requires a user and an idToken");
  }

  const wallets = await getWallets(user, config);

  // let the frontend know about the new wallet
  // note - the frontend can also find out by simply reloading - it doesn't rely on this cookie
  // for the wallet information
  await authStorage.set("civic-embedded-wallet", JSON.stringify(wallets));

  return wallets;
};
