"use client";
import { CivicAuthProvider as CivicNextAuthProvider } from "@civic/auth/nextjs";
import React, { type ReactNode, useCallback, useState } from "react";
import { type NextCivicAuthWeb3ProviderProps } from "../types.js";
import { Web3UserProvider } from "../components/Web3UserProvider.js";

// A wrapper around civic auth that injects an embedded wallet.
// TODO remove this and just use components/CivicAuthProvider.tsx
export function CivicAuthProvider({
  children,
  ...props
}: NextCivicAuthWeb3ProviderProps & { children: ReactNode }) {
  // allow the embedded wallet to add a hook to the logout process, ensuring its state gets cleaned up
  // for security reasons, and to avoid bugs when changing users
  const [logoutCallback, setLogoutCallback] = useState<() => Promise<void>>();
  const registerLogout = useCallback(
    (callback: () => Promise<void>) => setLogoutCallback(() => callback),
    [setLogoutCallback],
  );

  return (
    <CivicNextAuthProvider
      {...props}
      onSignOut={async () => {
        await logoutCallback?.();
      }}
    >
      <Web3UserProvider registerLogoutCallback={registerLogout} {...props}>
        {children}
      </Web3UserProvider>
    </CivicNextAuthProvider>
  );
}
