import type { NextConfig } from "next";
import { logger } from "../lib/logger.js";

/**
 * This is a nextjs webpack plugin which, if enableSolanaWalletAdapter is *not set*, will
 * replace the useWallet and useConnection hooks with fallback hooks that do not attempt to
 * import the solana wallet adapter.
 *
 * It is required in order to resolve an issue with webpack attempting to statically import
 * dynamic imports during build.
 */

type NextWebpackConfig = Parameters<NonNullable<NextConfig["webpack"]>>[0];
export type WebpackConfigContext = Parameters<
  NonNullable<NextConfig["webpack"]>
>[1];

export const withOptionalSolanaDependencies = (
  nextConfig: NextConfig = {},
  config: {
    enableSolanaWalletAdapter?: boolean;
  },
) => {
  if (config.enableSolanaWalletAdapter) {
    // If the user has enabled Solana Wallet Adapter, we don't need to modify the webpack config to use the
    // fallback hooks
    logger.web3.config.debug("Enabling Solana Wallet Adapter mode");
    return nextConfig;
  } else {
    logger.web3.config.debug(
      "Disabling Solana Wallet Adapter mode - use fallback hooks in webpack-built projects to avoid dependency import errors.",
    );
  }

  return {
    ...nextConfig,
    webpack(config: NextWebpackConfig, options: WebpackConfigContext) {
      // First call the original webpack config function if it exists
      if (typeof nextConfig.webpack === "function") {
        config = nextConfig.webpack(config, options);
      }

      // switch the useWallet and useConnection imports to the fallback versions
      // to avoid webpack attempting (and failing) to import the solana wallet-adapter
      config.resolve.alias["./useWalletOptional.js"] =
        "./useWalletOptionalFallback.js";
      config.resolve.alias["./useConnectionOptional.js"] =
        "./useConnectionOptionalFallback.js";

      return config;
    },
  };
};
