import { printVersion } from "../shared/index.js";

printVersion();
import {
  createCivicAuthPlugin as internalCivicAuthPlugin,
  NextjsCookieStorage,
  resolveAuthConfig,
} from "@civic/auth/nextjs";
import type { AuthConfig } from "@civic/auth/nextjs";
import type { NextConfig } from "next";

export {
  NextjsCookieStorage,
  handler,
  defaultAuthConfig,
} from "@civic/auth/nextjs";
export type {
  AuthConfig,
  CookiesConfigObject,
  AuthConfigWithDefaults,
} from "@civic/auth/nextjs";
export { CivicAuthProvider } from "./CivicAuthProvider.js";
import { isLoggedIn } from "@civic/auth/nextjs";
import {
  createWallets as createWalletsInternal,
  getWallets as getWalletsInternal,
  getUser as getUserShared,
} from "../server/functions.js";
import type {
  CivicWeb3ClientConfig,
  GetWalletsResponse,
  UserDetailsWithAuth,
} from "../types.js";
export { getTokens, isLoggedIn, resolveAuthConfig } from "@civic/auth/nextjs";
import type { User } from "@civic/auth";
import { withOptionalSolanaDependencies } from "./webpackPlugin.js";

const getAuthConfig = () => {
  const authConfig = resolveAuthConfig();
  return {
    ...authConfig,
    redirectUrl: authConfig.callbackUrl,
  };
};
export const getUser = async <T extends UserDetailsWithAuth>(
  config: CivicWeb3ClientConfig = {},
): Promise<User<T> | null> => {
  const hasValidSession = await isLoggedIn();
  if (!hasValidSession) return null;

  return getUserShared<T>(config, new NextjsCookieStorage(), getAuthConfig());
};

export const createWallets = async (config: CivicWeb3ClientConfig = {}) => {
  const hasValidSession = await isLoggedIn();
  if (!hasValidSession) return null;
  return createWalletsInternal(
    config,
    new NextjsCookieStorage(),
    getAuthConfig(),
  );
};
export const getWallets = async (
  config: CivicWeb3ClientConfig = {},
): Promise<GetWalletsResponse | null> => {
  const user = await getUser(config);
  if (!user) return null;
  return getWalletsInternal(user, config);
};

export type Web3AuthConfig = AuthConfig & {
  enableSolanaWalletAdapter?: boolean;
};

export const createCivicAuthPlugin = (
  authConfig: Web3AuthConfig,
): ((nextConfig?: NextConfig) => NextConfig) => {
  const authPlugin = internalCivicAuthPlugin({
    cookies: {
      tokens: {
        id_token: {
          httpOnly: false,
        },
      },
    },
    ...authConfig,
  });

  return (nextConfig = {}) => {
    // Apply the civic auth plugin
    const withAuth = authPlugin(nextConfig);

    // Apply the Solana dependency plugin
    return withOptionalSolanaDependencies(withAuth, authConfig);
  };
};
