"use client";

import { FC, PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CivicAuthProvider } from "@civic/auth-web3/nextjs";
import { polygonAmoy, baseSepolia, sepolia } from "viem/chains";
import { Chain, http } from "viem";
import { createConfig, WagmiProvider } from "wagmi";
import { ChainTypeProvider } from "@/components/ChainTypeContext";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { embeddedWallet } from "@civic/auth-web3/wagmi";

const queryClient = new QueryClient();

// Configure chains and RPC URLs.
export const supportedChains = [baseSepolia, polygonAmoy, sepolia] as [
  Chain,
  ...Chain[],
];

const wagmiConfig = createConfig({
  chains: supportedChains,
  transports: {
    [baseSepolia.id]: http(),
    [polygonAmoy.id]: http(),
    [sepolia.id]: http(),
  },
  connectors: [embeddedWallet()],
  ssr: true,
});

// Add this type for the Providers props
type ProvidersProps = PropsWithChildren<{
  onSessionEnd?: () => void;
}>;

export const Providers: FC<ProvidersProps> = ({ children }) => {
  const solanaDevnetEndpoint =
    process.env.NEXT_PUBLIC_SOLANA_DEVNET_RPC_URL ?? clusterApiUrl("devnet");

  return (
    <ChainTypeProvider>
      <QueryClientProvider client={queryClient}>
        <ConnectionProvider endpoint={solanaDevnetEndpoint}>
          <WalletProvider wallets={[]} autoConnect>
            <WagmiProvider config={wagmiConfig}>
              <CivicAuthProvider iframeMode={"embedded"}>
                {children}
              </CivicAuthProvider>
            </WagmiProvider>
          </WalletProvider>
        </ConnectionProvider>
      </QueryClientProvider>
    </ChainTypeProvider>
  );
};
