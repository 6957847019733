import type {
  CivicWeb3ClientConfig,
  UserDetailsWithAuth,
} from "../../types.js";
import type { User } from "@civic/auth";
import { CivicMetakeepApiClient } from "./civicApiClient.js";
import { logger } from "../logger.js";

/**
 * Server-side version of Web3Client interface
 * Stripped down to only include addresses, not actual wallet implementations
 * which don't work server-side
 */
export interface ServerWeb3Client {
  ethereum: { address: string | undefined };
  solana: { address: string | undefined };
  connected: boolean;

  createWallets(): Promise<{
    ethereum: { address: string };
    solana: { address: string };
  }>;

  disconnect(): Promise<void>;
}

/**
 * MetakeepServerWeb3Client - A simplified server-side implementation of the Web3Client interface
 * that works without browser-specific Metakeep SDK, only requiring API access.
 */
export class MetakeepServerWeb3Client implements ServerWeb3Client {
  ethereum: { address: string | undefined };
  solana: { address: string | undefined };
  connected: boolean = true;

  private civicApiClient: CivicMetakeepApiClient;

  constructor(
    private addresses: {
      ethereum: string | undefined;
      solana: string | undefined;
    },
    user: User<UserDetailsWithAuth>,
    config: CivicWeb3ClientConfig,
  ) {
    this.ethereum = { address: addresses.ethereum };
    this.solana = { address: addresses.solana };
    this.civicApiClient = new CivicMetakeepApiClient(user, config);
  }

  async createWallets() {
    // Create wallet if it doesn't exist
    if (!this.addresses.ethereum || !this.addresses.solana) {
      const { wallet } = await this.civicApiClient.createWallet();
      this.addresses = {
        ethereum: wallet.ethAddress,
        solana: wallet.solAddress,
      };
      this.ethereum.address = this.addresses.ethereum;
      this.solana.address = this.addresses.solana;
    }

    // At this point, we should definitely have addresses
    if (!this.addresses.ethereum || !this.addresses.solana) {
      throw new Error("Failed to create or retrieve wallet addresses");
    }

    return {
      ethereum: { address: this.addresses.ethereum },
      solana: { address: this.addresses.solana },
    };
  }

  async disconnect() {
    // No actual disconnection needed on server side
    this.connected = false;
    return Promise.resolve();
  }

  /**
   * Build a MetakeepServerWeb3Client instance
   * This follows the same pattern as MetakeepWeb3Client.build() to maintain consistency
   */
  static async build<TUserDetails extends UserDetailsWithAuth>(
    config: CivicWeb3ClientConfig,
    user: User<TUserDetails>,
  ): Promise<ServerWeb3Client> {
    const civicApiClient = new CivicMetakeepApiClient(user, config);

    // Extract wallet addresses from user if available
    const { ethWalletAddress, solWalletAddress } = user;
    let addresses = { ethereum: ethWalletAddress, solana: solWalletAddress };

    // If the wallet addresses are not part of the user object
    // assume they don't have a wallet yet, and create one
    if (!ethWalletAddress && !solWalletAddress) {
      logger.web3?.metakeep?.debug?.(
        "No wallet present in user object - creating one",
      );
      const { wallet } = await civicApiClient.createWallet();
      addresses = {
        ethereum: wallet.ethAddress,
        solana: wallet.solAddress,
      };
    }

    // If the user still doesn't have a wallet, throw an error
    if (!addresses.ethereum || !addresses.solana) {
      throw new Error(
        `Failed to create wallet: Eth address: ${addresses.ethereum}, Sol address: ${addresses.solana}`,
      );
    }

    return new MetakeepServerWeb3Client(addresses, user, config);
  }
}
